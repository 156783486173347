import React from "react";

const Commission: React.FC = () => {
  return (
    <div className="page">
      <div className="sentence">
        <div className="space" />
        <div className="space" />
        <div className="title">
          <h1>Commission</h1>
        </div>
        <div className="main">
          <p>
            ※このページに記載した料金設定/依頼の流れ等はまだ検討段階であり、今後変更される可能性が高いです。
          </p>
          <p>
            ※このページの文章は
            <a
              href="https://sopieizo.work"
              target="_blank"
              rel="noopener noreferrer"
            >
              sopieizo
            </a>
            さんのウェブサイトを参考にしています
          </p>
          <br />
          <h1>1.見積もり</h1>
          <br />
          <p>rebuild.up.up※gmail.comまたはXのDMにて以下の内容を教えて下さい</p>
          <p>・何を作るか (歌ってみたのMV制作など)</p>
          <p>・納期 (目安は1ヶ月です)</p>
          <p>・予算 (料金の例はこちら)</p>
          <p>・作る映像の尺または楽曲名</p>
          <p>・イメージ (指標となる作例や、箇条書き、絵コンテ、なんでもOK!)</p>
          <p>
            ※場合によっては、追加で音源やイラスト、曲名などを教えていただくようお願いすることがあります。
          </p>
          <p>
            それらの情報から、納期や料金などをお伝えいたします。もし追加で知りたい情報があれば気軽に質問して下さい!
          </p>
          <br />
          <h1>2.素材を渡す</h1>
          <br />
          <p>
            映像の中で使いたい素材
            (イラストや動画素材など)があればGigaFile便やGoogleドライブなどで送って下さい。
          </p>
          <br />
          <h1>3.制作</h1>
          <br />
          <p>
            MVの場合はサビ前あたりまで完成させ、一度GigaFile便にて進捗を送付させていただきます。その際、「ここをこうしてほしい」などのリテイクが必要な場合はお伝え下さい。
          </p>
          <p>
            リテイクはこちらのミスを除き、無料で3回まで引き受けさせていただきます。4回目以降のリテイクに関しましては、一回につき依頼報酬の半額を報酬額に加えさせていただきます。
          </p>
          <br />
          <h1>4.納品</h1>
          <br />
          <p>
            映像が完成して、リテイクのご指示がない場合は、GigaFile便にて納品させていただきます。
          </p>
          <br />
          <h1>5.お支払い</h1>
          <br />
          <p>お支払いは納品確認から30日以内にお振込みをお願い致します。</p>
          <p>お支払い方法</p>
          <p>・銀行振込</p>
          <p>・PayPay</p>
          <p>・Amazonギフト</p>
          <br />
          <hr />
          <br />
          <h1>料金</h1>
          <br />
          <p>・歌ってみたMV制作 5000円~</p>
          <p>・オリジナルMV制作 6000円~</p>
          <p>・YoutubeなどのOP,ED,トランジションなど(20秒以内) 1000円~</p>
          <br />
          <p>
            ※料金設定は変更する場合がございます。依頼時点でここに記載されている金額が料金となります。
          </p>
          <p>※制作を開始してからのキャンセル料は本来の料金の50%です。</p>
          <p>※尺や納期、料金によってはお断りする場合がございます。</p>
          <p>※その他ジャンルの映像の料金は要相談</p>
          <br />
          <br />
          <hr />
          <br />
          <h1>その他</h1>
          <br />
          <p>通話などでのミーティングの対応は致しかねます</p>
        </div>
        <div className="space" />
        <div className="space" />
        <div className="space" />
        <div className="space" />
      </div>
    </div>
  );
};

export default Commission;
