import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

// Lazy load
const VideoDetail = React.lazy(() => import("./008_VideoDetail"));
const FilterButtons = React.lazy(() => import("./009_FilterButton"));

interface VideoLink {
  url: string;
  description: string;
}

interface VideoData {
  id: number;
  type: "video" | "image";
  title: string;
  tags: string[];
  description: string;
  tools: string[];
  thumbnail: string;
  video: string;
  image: string;
  links: VideoLink[];
}

const VideoGallery: React.FC = () => {
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]); // 複数タグの選択を管理

  useEffect(() => {
    // JSONファイルの取得
    fetch("/assets/videos.json")
      .then((res) => res.json())
      .then((data) => {
        // IDの降順にソート
        const sortedData = data.sort(
          (a: VideoData, b: VideoData) => b.id - a.id
        );
        setVideos(sortedData);
      })
      .catch((error) => console.error("Error loading video data:", error));
  }, []);

  const filteredVideos =
    selectedTags.length > 0
      ? videos.filter(
          (video) => selectedTags.every((tag) => video.tags.includes(tag)) // すべての選択タグが含まれる動画のみ表示
        )
      : videos;

  // タグの選択/解除
  const toggleTag = (tag: string) => {
    setSelectedTags(
      (prevTags) =>
        prevTags.includes(tag)
          ? prevTags.filter((t) => t !== tag) // タグが選択されていれば解除
          : [...prevTags, tag] // 選択されていなければ追加
    );
  };

  return (
    <Router>
      <Routes>
        {/* サムネイル一覧ページ */}
        <Route
          path="/"
          element={
            <div className="work">
              <div className="filters">
                <Suspense fallback={<div>Loading filters...</div>}>
                  <FilterButtons
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    toggleTag={toggleTag}
                  />
                </Suspense>
              </div>
              <div className="gallery-container">
                <div className="gallery">
                  {filteredVideos.map((video) => (
                    <div key={video.id} className="thumbnail-container">
                      <Link to={`/video/${video.id}`}>
                        {video.thumbnail.endsWith(".webm") ? (
                          <video
                            src={video.thumbnail}
                            className="thumbnail"
                            autoPlay
                            loop
                            muted
                            playsInline
                          />
                        ) : (
                          <img
                            src={video.thumbnail}
                            alt={video.title}
                            className="thumbnail"
                          />
                        )}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
        />

        {/* 詳細ページ */}
        <Route
          path="/video/:id"
          element={
            <Suspense fallback={<div>Loading video details...</div>}>
              <VideoDetail videos={videos} />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default VideoGallery;
